import React from "react";
import mock from "../../assets/img/round-mock.png";
import { RiFileCheckLine, RiContactsBook2Line } from "react-icons/ri";
import { IoLogoUsd } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa6";

const data = [
  {
    id: 1,
    title: "Easy to use",
    icon: <RiFileCheckLine className="xl:text-[80px] text-3xl" />,
    description: "The most secure way to sell your Bitcoin!",
  },
  {
    id: 2,
    title: "Fast payments",
    icon: <IoLogoUsd className="xl:text-[80px] text-3xl" />,
    description:
      "Open the app and follow the quick and easy sign-up process. All you need is your basic personal information.",
  },
  {
    id: 3,
    title: "Free registration",
    icon: <RiContactsBook2Line className="xl:text-[80px] text-3xl" />,
    description: "The most secure way to sell your Bitcoin!",
  },
  {
    id: 4,
    title: "Safe and secured",
    icon: <FaUserCheck className="xl:text-[80px] text-3xl" />,
    description: "The most secure way to sell your Bitcoin!",
  },
];
const Steps = () => {
  return (
    <div className="bg-[#FEFD0C] h-full">
      <div className="w-full xl:py-[146px] py-[79px] mx-auto xl:px-[96px] px-[16px] flex flex-col xl:flex-row h-full overflow-hidden">
        <div className="w-full flex flex-col xl:flex-row justify-between xl:gap-[100px] gap-[50px]">
          <div>
            <div className="md:grid md:grid-cols-2 w-full gap-14">
              {data?.map((item, index) => (
                <div
                  className="flex md:gap-12 gap-5 w-full md:py-3 py-6"
                  key={item?.id}
                >
                  <div>{item?.icon}</div>
                  <div>
                    <h3 className="font-times xl:text-[35px] tracking-wide text-[18px] text-black xl:w-[41.1rem] font-bold">
                      {item?.title}
                    </h3>
                    <p className="text-black font-normal xl:text-[20px] tracking-wide text-sm font-times xl:w-[30.3rem] md:w-[87%] w-full xl:py-2 leading-[150%]">
                      {item?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
