import React from "react";

const data = [
  {
    id: 1,
    title: "1. Log into your Account",
    desc: "The most secure way to sell your Bitcoin!",
  },
  {
    id: 2,
    title: "2. Copy our wallet address",
    desc: "The most secure way to sell your Bitcoin!",
  },
  {
    id: 3,
    title: "3. Send coins and upload proof",
    desc: "The most secure way to sell your Bitcoin!",
  },
];

const Cta = () => {
  return (
    <section className="h-full w-full bg-review bg-cover bg-center">
      <div className="w-full  mx-auto xl:px-[96px] px-[16px] flex flex-col xl:flex-row h-full overflow-hidden ">
        <div className="xl:py-[100px] pt-[99px]">
          <h3 className="font-times xl:text-[3rem] text-[40px] leading-[120%] text-black w-full font-semibold tracking-wide">
            How it works
          </h3>
          <div className="py-4 w-full md:mt-14 mt-9">
            {data?.map((item, index) => (
              <div
                className="flex items-center gap-4 w-full py-3"
                key={item?.id}
              >
                <div>
                  <h3 className="font-times xl:text-[35px] text-[18px] text-black xl:w-[41.1rem] font-semibold">
                    {item?.title}
                  </h3>
                  <p className="text-black font-normal xl:text-[20px] text-sm font-times xl:w-[30.3rem] w-[87%] xl:py-2 leading-[150%]">
                    {item?.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:h-[685px] md:w-[600px] h-[650px] w-full relative bottom-[-100px]">
          <img
            src="https://ik.imagekit.io/shiga/sfpf/Group%204110(1).png?updatedAt=1713658575970"
            className="h-full w-full object-cover absolute"
            alt="Mock"
          />
        </div>
      </div>
    </section>
  );
};

export default Cta;
