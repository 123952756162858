import React from "react";
import { Link } from "react-router-dom";
import Qrcode from "./Qrcode";
import { AppButton } from "./Buttons";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import logo from "../../assets/img/logo-white.png";
import { FaWhatsapp } from "react-icons/fa6";

function Footer() {
  return (
    <section className="w-full bg-black">
      <div className="flex flex-col items-center w-full justify-between xl:px-[96px] px-[16px] mx-auto xl:pt-[97px] pb-[58px] pt-[77px]">
        <div className="flex flex-col xl:flex-row w-full h-full relative">
          <div className="xl:w-[35%] w-full">
            <img
              src={logo}
              alt="Footer"
              className="h-[60px] w-[200px] pointer-events-none object-contain"
            />
            <p className="font-normal xl:text-[18px] tracking-wide text-[14px] leading-[30px] xl:w-[400px] w-full text-[#FEFD0C] font-times pt-[8px]">
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
            </p>
            <ul className="mt-[32px]">
              <li className="mb-[16px]">
                <Link
                  to="tel:+27697153218"
                  className="xl:text-[16px] text-[14px] font-times  text-white font-normal flex items-center gap-5"
                >
                  <FiPhoneCall className="text-white text-[22px]" />
                  +27697153218
                </Link>
              </li>
              <li className="mb-[16px]">
                <Link
                  to="#"
                  className="xl:text-[16px] text-[14px]  font-times  text-white font-normal flex items-center gap-5"
                >
                  <HiOutlineLocationMarker className="text-white text-[22px]" />{" "}
                  London, United Kingdom
                </Link>
              </li>
            </ul>
          </div>
          <div className="xl:w-[65%] w-full xl:grid xl:grid-cols-3 flex flex-col gap-[70px] z-50">
            <div className="flex flex-col items-start justify-start w-full xl:mt-0 mt-[71px]">
              <h3 className="font-medium xl:text-[20px] font-times text-base  text-white">
                Quick links
              </h3>
              <ul className="mt-[32px]">
                <li className="mb-[16px]">
                  <Link
                    to="/"
                    className="xl:text-[16px] text-[14px] font-times  text-white font-normal "
                  >
                    Home
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/rates"
                    className="xl:text-[16px] text-[14px] font-times  text-white font-normal "
                  >
                    Rates
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/about-us"
                    className="xl:text-[16px] text-[14px] font-times  text-white font-normal "
                  >
                    About Us
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="#faq"
                    className="xl:text-[16px] text-[14px] font-times  text-white font-normal "
                  >
                    Faq
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/support"
                    className="xl:text-[16px] text-[14px] font-times  text-white font-normal "
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full hidden xl:block">
              <Qrcode variant="secondary" />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <h3 className="font-medium text-[14px] xl:text-[20px] font-times  text-white">
                Download App on
              </h3>
              <div className="flex flex-col items-center gap-y-6 mt-[36px]">
                <AppButton
                  icon={FaGooglePlay}
                  label="Get it on"
                  placeholder="Google Play"
                  href="https://play.google.com/store/apps/details?id=com.brandboxafrica.sellfast&hl=en&gl=US&pli=1"
                />
                <AppButton
                  icon={FaApple}
                  label="Download on the"
                  placeholder="App store"
                  href="https://apps.apple.com/us/app/sellfastpayfast/id1525149238"
                />
              </div>
            </div>
          </div>
          <img
            src="https://ik.imagekit.io/shiga/sfpf/6258275%201.png?updatedAt=1713658872946"
            className="absolute h-full w-[545px] object-cover object-center right-[-100px] top-[150px] bottom-0 xl:block hidden"
            alt=""
          />
        </div>
        <div className="flex items-center xl:mt-[69px] mt-[100px] w-full">
          <h3 className="xl:text-base text-[14px] text-white opacity-70 font-times w-full">
            Copyright © Sellfastpayfast
          </h3>
        </div>
      </div>
      <a
        href="https://wa.link/y6j85x"
        className="float flex items-center justify-center"
        target="_blank"
      >
        <FaWhatsapp className="text-[32px] text-white" />
      </a>
    </section>
  );
}

export default Footer;
