import React from "react";

const Hero = () => {
  return (
    <div className=" w-full bg-black xl:py-[80px] py-[40px]">
      <div className="container mx-auto md:px-0 px-4">
        <div>
          <h3 className="text-[#FEFD0C] font-bold xl:text-[20px]">
            RATE CALCULATOR
          </h3>
          <p className="py-3 text-white xl:text-[50px] text-base">
            Know how much you stand to gain
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
