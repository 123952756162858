import React from "react";
import PageLayout from "../layouts/PageLayout";
import Hero from "../components/rate/Hero";
import Calculator from "../components/rate/Calculator";

const Rate = () => {
  return (
    <PageLayout>
				<Hero />
				<Calculator />
    </PageLayout>
  );
};

export default Rate;
