import React from "react";
import PageLayout from "../layouts/PageLayout";
import About from "../components/about/About";
import Values from "../components/about/Values";
import Review from "../components/home/Review";

const AboutUs = () => {
  return (
    <PageLayout>
      <About />
      <Values />
      <Review />
    </PageLayout>
  );
};

export default AboutUs;
