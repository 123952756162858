import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Rate from "./pages/rate";
import ContactUs from "./pages/contact";
import AboutUs from "./pages/about";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rates" element={<Rate />} />
        <Route path="/support" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
