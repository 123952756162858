import React, { useState } from "react";
import logo from "../../assets/img/logo-white.png";
import { FaGlobeAfrica, FaAngleDown } from "react-icons/fa";
import { HiMenuAlt4 } from "react-icons/hi";
import { RiCloseFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  const [open, setOpen] = useState(false);

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <div className="w-full bg-white md:py-6 py-4">
        <div className="flex items-center w-full justify-between xl:px-[96px] px-[16px] mx-auto">
          <Link to="/" className="flex items-center cursor-pointer">
            <img
              src="https://ik.imagekit.io/shiga/sfpf/logo-black.png?updatedAt=1713647052931"
              className="w-[180px] h-[47px] pointer-events-none object-contain"
              alt="Logo"
            />
          </Link>
          <div
            className="h-[50px] w-[50px] rounded-full bg-[#FEFD0C] xl:hidden flex items-center justify-center cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <RiCloseFill className="text-black text-[30px]" />
            ) : (
              <HiMenuAlt4 className="text-black text-[30px]" />
            )}
          </div>
          <div className="xl:flex hidden items-center gap-[38px]">
            <Link to="/">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "" ? `text-black` : `text-black`
                }`}
              >
                Home
              </h3>
            </Link>{" "}
            <Link to="/rates">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "rates" ? `text-black` : `text-black`
                }`}
              >
                Rates
              </h3>
            </Link>
            <Link to="/about-us">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "about-us" ? `text-black` : `text-black`
                }`}
              >
                About Us
              </h3>
            </Link>
            <Link to="#faq">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "faq" ? `text-black` : `text-black`
                }`}
              >
                Faq
              </h3>
            </Link>
            <Link to="/support">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "faq" ? `text-black` : `text-black`
                }`}
              >
                Support
              </h3>
            </Link>
            <div className="rounded-[200px] border border-black p-[12px] flex items-center justify-between cursor-pointer">
              <div className="flex items-center">
                <h3 className="text-black text-base font-medium font-times ml-4 mr-[28px]">
                  Let's trade
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className="w-full bg-[#000] h-screen px-[16px] py-[60px]">
          <div className="flex flex-col w-full items-center md:gap-[58px] gap-[40px]">
            <Link to="/">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "about-us" ? `text-white` : `text-white`
                }`}
              >
                Home
              </h3>
            </Link>
            <Link to="/rates">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "rates" ? `text-white` : `text-white`
                }`}
              >
                Rates
              </h3>
            </Link>
            <Link to="/about-us">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "about-us" ? `text-white` : `text-white`
                }`}
              >
                About Us
              </h3>
            </Link>
            <Link to="/faq">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "faq" ? `text-white` : `text-white`
                }`}
              >
                Faq
              </h3>
            </Link>
            <Link to="/support">
              <h3
                className={` text-[18px] font-medium font-times ${
                  splitLocation[1] === "support" ? `text-white` : `text-white`
                }`}
              >
                Support
              </h3>
            </Link>
            <div className="rounded-[200px] border border-[#FEFD0C] p-[14px] w-[180px] flex items-center justify-center cursor-pointer ">
              <div className="flex items-center">
                <h3 className="text-[#FEFD0C] text-base font-medium font-times ml-4 mr-[28px]">
                  Let's trade
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
