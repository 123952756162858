import React from "react";
import Contact from "../components/support/Contact";
import PageLayout from "../layouts/PageLayout";

const ContactUs = () => {
  return (
    <PageLayout>
      <Contact />
    </PageLayout>
  );
};

export default ContactUs;
