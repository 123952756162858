import React from "react";

const About = () => {
  return (
    <section className="w-full md:py-[100px] py-[40px] h-full">
      <div className="container mx-auto w-full h-full ">
        <div className="flex md:flex-row flex-col items-center gap-8 w-full h-full">
          <div className="xl:w-[50%] w-full md:px-0 px-4">
            <h3 className="text-black xl:text-[45px] text-[30px] tracking-wide font-extrabold">
              Our Story
            </h3>
            <p className="md:text-base text-sm pt-5 md:w-[90%] w-full leading-[180%]">
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
            </p>
          </div>
          <div className="xl:w-[50%] md:px-0 px-4">
            <div className="xl:w-[90%] rounded-[32px] shadow-sm md:h-[600px] h-[400px] overflow-hidden">
              <img
                src="https://ik.imagekit.io/shiga/sfpf/Screenshot%202024-04-21%20at%2014.01.30.png?updatedAt=1713705786488"
                alt="CEO"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col-reverse items-center gap-8 w-full h-full md:mt-[100px] mt-[50px]">
          <div className="xl:w-[50%] md:px-0 px-4">
            <div className="xl:w-[90%] rounded-[32px] shadow-sm md:h-[600px] h-[400px] overflow-hidden">
              <img
                src="https://ik.imagekit.io/shiga/sfpf/Group%20913(1).png?updatedAt=1713736326378"
                alt="CEO"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="xl:w-[50%] w-full md:px-0 px-4">
            <h3 className="text-black xl:text-[32px] text-[25px] tracking-wide font-extrabold">
              What we do (Value proposition/Mission)
            </h3>
            <p className="md:text-base text-sm pt-5 md:w-[80%] w-full leading-[180%]">
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
