import React from "react";
import bitcoin from "../../assets/img/bitcoin.png";

const data = [
  {
    id: 1,
    country: "Nigeria",
    buy: "₦1,590/USD",
    sell: "₦1,630/USD",
    flag: "https://ik.imagekit.io/shiga/sfpf/nigeria.png?updatedAt=1713664772477",
  },
  {
    id: 2,
    country: "South Africa",
    buy: "ZAR17.5/USD",
    sell: "ZAR19.8/USD",
    flag: "https://ik.imagekit.io/shiga/sfpf/south-africa.png?updatedAt=1713664771931",
  },
  {
    id: 3,
    country: "UK",
    buy: "-10%",
    sell: "+5%",
    flag: "https://ik.imagekit.io/shiga/sfpf/united-kingdom.png?updatedAt=1723167716922",
  },
];

const Calculator = () => {
  return (
    <section className="bg-review bg-center bg-cover bg-no-repeat h-full w-full py-[70px]">
      <div className="container mx-auto w-full h-full md:px-0 px-4">
        <div className="md:w-[60%] w-full  mx-auto bg-white shadow-md rounded-[22px] p-[20px]">
          <div className="flex items-center gap-3">
            <img
              src={bitcoin}
              className="md:h-[40px] md:w-[40px] w-[30px] h-[30px]	object-cover"
              alt="bitcoin"
            />
            <p className="py-3 text-black xl:text-[20px] text-sm">
              Bitcoin Trading Rate
            </p>
          </div>
          <div className="md:max-w-[90%] w-full mx-auto">
            <div className="mt-4 w-full flex items-center justify-between md:text-[20px] text-sm font-extrabold">
              <h3> COUNTRY</h3>
              <h3> BUY</h3>
              <h3> SELL</h3>
            </div>
            <div>
              {data?.map((item, index) => (
                <div
                  className="flex items-center justify-between w-full md:py-3 py-1 gap-4"
                  key={item?.id}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={item?.flag}
                      className="md:h-[30px] md:w-[30px] h-[20px] w-[20px]	object-cover"
                      alt={item?.country}
                    />
                    <p className="py-3 text-black xl:text-base text-xs">
                      {item?.country}
                    </p>
                  </div>
                  <p className="py-3 text-black xl:text-base text-xs">
                    {item?.buy}
                  </p>
                  <p className="py-3 text-black xl:text-base text-xs">
                    {item?.sell}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
