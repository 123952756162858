import React from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import {
  RiTiktokLine,
  RiInstagramLine,
  RiTwitterLine,
  RiLinkedinLine,
} from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const items = [
  {
    id: 1,
    title: "Email",
    icon: MdOutlineAlternateEmail,
    url: "mailto:#",
  },
  {
    id: 2,
    title: "TikTok",
    icon: RiTiktokLine,
    url: "#",
  },
  {
    id: 3,
    title: "Facebook",
    icon: FaFacebookF,
    url: "#",
  },
  {
    id: 4,
    title: "Instagram",
    icon: RiInstagramLine,
    url: "https://www.instagram.com/sfpfglobal?igsh=cDI0eWd6dHZucnZt",
  },
  {
    id: 5,
    title: "Twitter",
    icon: RiTwitterLine,
    url: "#",
  },
  {
    id: 6,
    title: "LinkedIn",
    icon: RiLinkedinLine,
    url: "#",
  },
];

interface DataItem {
  id: number;
  url: any;
  title: string;
  icon: any;
}

function Contact() {
  return (
    <section className=" bg-review bg-cover bg-no-repeat h-full bg-center">
      <div className="w-full mx-auto md:pl-[96px] pl-0 flex flex-col md:flex-row h-full overflow-hidden">
        <div className="md:w-[40%] w-full md:pt-[150px] pt-[80px] md:pb-0 pb-[50px] px-[16px] md:px-0">
          <h3 className=" leading-[120%] font-medium md:text-[48px] text-[36px]">
            <span className="font-sansbold text-[#120D31] font-semibold">
              Get in touch
            </span>
            <br />
            <span className="font-sans font-medium text-black">
              We respond fast
            </span>
          </h3>
          <p className="font-sansregular font-normal text-black md:text-[28px] text-base md:mt-[48px] mt-[20px]">
            Our support team is always available
          </p>
        </div>
        <div className="md:w-[60%] w-full md:px-[102px] px-[16px] md:py-[91px] pb-[59px] grid md:grid-cols-3 grid-cols-2 md:gap-[18px] gap-[24px] h-full place-content-center mx-auto">
          {items.map((item: DataItem, index) => {
            const Icon = item?.icon;
            return (
              <Link
                to={item.url}
                key={index}
                className="w-full rounded-[16px] border border-black/70 md:px-[20px] px-[30px] cursor-pointer flex items-start flex-col h-full max-h-[180px] md:hover:shadow-md md:hover:transition md:hover:delay-50 md:hover:ease-in"
              >
                <div className="py-[28px]">
                  <div className="icon-container">
                    <div className="square bg-black w-[60px]  flex items-center justify-center md:h-[60px] h-[50px] absolute rounded-[8px]">
                      <Icon className="text-[#FEFD0C] md:text-[30px] text-[30px]" />
                    </div>
                    <div className="rotated-square rounded-[8px]"></div>
                  </div>
                  <h3 className="text-black font-normal font-sansregular md:text-[22px] text-[18px] pt-[10px]">
                    {item.title}
                  </h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Contact;
